import {
  OBTAIN_SIDEBAR_CONTACTS_DATA,
  OBTAIN_SIDEBAR_CONTACTS_ERROR,
  OBTAIN_CONTACTS_DATA,
  OBTAIN_CONTACTS_ERROR,
  // OBTAIN_TEAM_ERROR,
  // OBTAIN_INVITE_DATA,
  // OBTAIN_INVITE_ERROR,
  // OBTAIN_TEAM_DATA_LOADING,
  // UNREAD_LIST,
  // UNREAD_LIST_ERROR,
} from "../actions";

const initialState = {
  // Contacts for sidebar
  contactsSidebarList: [],
  contactsSidebarListNextCursor: null,
  contactsSidebarListErrors: [],

  // Contacts for all contacts list
  contactsList: [],
  contactsListTotal: 0,
  contactsListNextCursor: null,
  contactsListErrors: [],

  // teamList: [],

  // teamListLoading: false,
  // teamListErrors: [],
  // inviteList: [],
  // inviteListErrors: [],
  // unread_list: [],
  // unread_listCount: null,
  // unread_listNextOffset: null,
  // unread_listLoading: false,
  // unread_listErrors: [],
};

export default function contactsReducer(state = initialState, action) {
  switch (action.type) {
    case OBTAIN_SIDEBAR_CONTACTS_DATA:
      return {
        ...state,
        contactsSidebarList: action.payload.contactsList,
        contactsSidebarListNextCursor: action.payload.nextCursor,
        contactsSidebarListErrors: [],
      };
    case OBTAIN_SIDEBAR_CONTACTS_ERROR:
      return {
        ...state,
        contactsSidebarListErrors: action.payload.errors,
      };

    case OBTAIN_CONTACTS_DATA:
      return {
        ...state,
        contactsList: action.payload.contactsList,
        contactsListTotal: action.payload.contactsTotal,
        contactsListNextCursor: action.payload.nextCursor,
        contactsListErrors: [],
      };
    case OBTAIN_CONTACTS_ERROR:
      return {
        ...state,
        contactsListErrors: action.payload.errors,
      };
    // case OBTAIN_TEAM_DATA:
    //   return { ...state, teamList: action.payload.data, teamListNextCursor: action.payload.nextCursor };
    // case OBTAIN_TEAM_DATA_LOADING:
    //   return { ...state, teamListLoading: action.payload };
    // case OBTAIN_TEAM_ERROR:
    //   return { ...state, teamListErrors: action.payload };
    // case OBTAIN_INVITE_DATA:
    //   return { ...state, inviteList: action.payload };
    // case OBTAIN_INVITE_ERROR:
    //   return { ...state, inviteListErrors: action.payload };
    // case UNREAD_LIST:
    //   return {
    //     ...state,
    //     unread_list: action.payload.data,
    //     unread_listNextOffset: action.payload.nextOffset,
    //     unread_listCount: action.payload.count,
    //   };
    // case UNREAD_LIST_ERROR:
    //   return { ...state, unread_listErrors: action.payload };
    default:
      return state;
  }
}
