import { API } from "./Api";
import axios from "axios";
import Logger from "./Logger";

function throttled(delay, fn) {
  let lastCall = 0;
  return function(...args) {
    const now = new Date().getTime();
    if (now - lastCall < delay) {
      return;
    }
    lastCall = now;
    return fn(...args);
  };
}

function getPresignedUrl(filename, size, type) {
  return API.post("file/get_presigned_url", { filename, size, type });
}

function uploadToS3(url, currentFile, presignedFields, onProgress) {
  const formData = new FormData();
  Object.keys(presignedFields).forEach(element => {
    formData.append(element, presignedFields[element]);
  });
  formData.append("file", currentFile);

  const options = {
    headers: { "Content-Type": "multipart/form-data" },
    onUploadProgress: progressEvent => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      throttled(300, onProgress(percentCompleted));
    },
  };

  return axios.post(url, formData, options);
}

function uploadCallback(filename, { type, projectId, incognito_mode }, presignedData) {
  return API.post("file/upload_callback", {
    filename,
    type,
    projectId,
    incognito_mode,
    policy: presignedData.fields.policy,
    signature: presignedData.fields["x-amz-signature"],
  });
}

const S3Upload = async (
  currentFile,
  { type, projectId, incognito_mode },
  onProgress = progress => {
    Logger.info(`Progress: ${progress}`);
  },
  onProgressComplete = () => Logger.info("Upload complete"),
) => {
  console.log(type, projectId, incognito_mode);
  try {
    const presignedDataResponse = await getPresignedUrl(currentFile.name, currentFile.size, type);
    const presignedData = presignedDataResponse.data;
    Logger.info(presignedData);

    const amazonS3Response = await uploadToS3(presignedData.url, currentFile, presignedData.fields, onProgress);
    Logger.info(amazonS3Response);

    const uploadCallbackResponse = await uploadCallback(
      currentFile.name,
      { type, projectId, incognito_mode },
      presignedData,
    );
    Logger.info(uploadCallbackResponse);
    onProgressComplete(uploadCallbackResponse);
  } catch (err) {
    Logger.info(err);
  }
};

export default S3Upload;
