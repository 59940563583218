import React, { Component, lazy } from "react";
import propTypes from "prop-types";
import { connect } from "react-redux";
import { Switch, withRouter, Redirect, Route } from "react-router";
// import { loadReCaptcha } from "react-recaptcha-v3";
// import { loadReCaptcha } from "react-recaptcha-v3";
import { reconnectSocket } from "./modules/Socket";
import { PrivateRoute, OpenRoute } from "./Routes";
import "./App.scss";

const LoginLayout = lazy(() => import(/* webpackChunkName: "LoginLayout" */ "./layouts/LoginLayout"));
const MainLayout = lazy(() => import(/* webpackChunkName: "MainLayout" */ "./layouts/MainLayout"));

class App extends Component {
  // componentDidMount(){
  //   loadReCaptcha("6Ld4ZcUUAAAAAG6kcDGzAQYdyIglfdqas6S755oP");
  // }
  componentDidUpdate(prevProps) {
    if (this.props.userId) {
      if (prevProps.userId !== this.props.userId) {
        reconnectSocket(this.props.userId);
      }
    }
  }
  render() {
    return (
      <div>
        <Switch>
          <PrivateRoute path="/invoices" component={MainLayout} auth={this.props.isAuth} />
          <PrivateRoute path="/support" component={MainLayout} auth={this.props.isAuth} />
          <PrivateRoute path="/contacts" component={MainLayout} auth={this.props.isAuth} />
          <PrivateRoute path="/profile" component={MainLayout} auth={this.props.isAuth} />
          <PrivateRoute path="/assessment" component={MainLayout} auth={this.props.isAuth} />
          <OpenRoute path="/" component={LoginLayout} />
          <Redirect from="*" to="/" />
        </Switch>
      </div>
    );
  }
}

App.propTypes = {
  isAuth: propTypes.bool.isRequired,
};

const mapStateToProps = ({ login, profile }) => ({
  isAuth: login.isAuth,
  userId: profile.id,
});

const mapDispatchToProps = {};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(App),
);
