import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import loginReducer from "./login";
import profileReducer from "./profile";
import contactsReducer from "./contacts";
import cardsReducer from "./cards";
import dialogsReducer from "./dialogs";
import fileUploadReducer from "./fileUpload";
import chatBoxReducer from "./chatBox";
import supportReducer from "./support";
import smileReducer from "./smile";
import snackbarNotificationReducer from "./snackbarNotification";
import invoicesReducer from "./invoices";
import notificationsReducer from "./notifications";

export default history =>
  combineReducers({
    router: connectRouter(history),
    login: loginReducer,
    profile: profileReducer,
    contacts: contactsReducer,

    cards: cardsReducer,
    dialogs: dialogsReducer,

    invoices: invoicesReducer,

    notifications: notificationsReducer,

    fileUpload: fileUploadReducer,
    chatBox: chatBoxReducer,
    support: supportReducer,
    smile: smileReducer,
    snackbarNotification: snackbarNotificationReducer,
  });
