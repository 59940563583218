import { API } from "../modules/Api.js";
import { CancelToken } from "axios";
import Logger from "../modules/Logger";
import { push } from "connected-react-router";
import { toggleCloseDialog, toggleDeleteDialog, setCardAdd } from "../actions";
export const SET_CARDS_USER = "SET_CARDS_USER";
export const SET_CARD_SENDING = "SET_CARD_SENDING";
export const SET_CARDS_LIST_CANCEL_TOKEN = "SET_CARDS_LIST_CANCEL_TOKEN";
export const START_OBTAIN_CARDS_LIST = "START_OBTAIN_CARDS_LIST";
export const OBTAIN_CARDS_LIST_DATA = "OBTAIN_CARD_LIST_DATA";
export const OBTAIN_CARDS_LIST_ERROR = "OBTAIN_CARD_LIST_ERROR";

export function setCardsUser(userId) {
  return {
    type: SET_CARDS_USER,
    payload: userId,
  };
}

function setCardSending(isSending) {
  return {
    type: SET_CARD_SENDING,
    payload: isSending,
  };
}


function cardsList(data, nextCursor) {
  return {
    type: OBTAIN_CARDS_LIST_DATA,
    payload: { data, nextCursor },
  };
}

function cardsListError(error) {
  return {
    type: OBTAIN_CARDS_LIST_ERROR,
    payload: error,
  };
}

function setCardsListCancelToken(token) {
  return {
    type: SET_CARDS_LIST_CANCEL_TOKEN,
    payload: token,
  };
}

function startObtainCardsList() {
  return {
    type: START_OBTAIN_CARDS_LIST,
  };
}

export const getCardsList = (page, search) => (dispatch, getState) => {
  dispatch(startObtainCardsList());

  const currentCancelToken = getState().cards.cardsListCancelToken;
  if (currentCancelToken) {
    currentCancelToken();
  }
  const currentUserId = getState().cards.cardsUser;

  const nextCursor = page !== 1 ? getState().cards.cardsListNextCursor : null;

  return API.get("/cards", {
    cancelToken: new CancelToken(token => {
      dispatch(setCardsListCancelToken(token));
    }),
    params: { cursor: nextCursor, id: currentUserId, search: search },
  }).then(
    response => {
      // console.log("response", response.data);
      const currentCardsList = page !== 1 ? [...getState().cards.cardsList] : [];
      const cardsListResults = [...currentCardsList, ...response.data.results];
      dispatch(cardsList(cardsListResults, response.data.next));
    },
    error => {
      Logger.info(error);
      dispatch(cardsListError(error));
    },
  );
};

export const createCard = cardData => (dispatch, getState) => {
  dispatch(setCardSending(true));
  API.post("/cards", cardData).then(
    response => {
      console.log(response);
      const updatedCards = [{ ...response.data }, ...getState().cards.cardsList];
      const currentCursor = getState().cards.cardsListNextCursor;
      dispatch(cardsList(updatedCards, currentCursor));
      dispatch(setCardSending(false));
      const currentUser = getState().profile.id;
      dispatch(push(`/profile/${currentUser}/projects`));
    },
    error => {
      console.log(error);
      dispatch(setCardSending(false));
    },
  );
};

export const updateCard = (cardId, cardData) => (dispatch, getState) => {
  dispatch(setCardSending(true));
  API.patch(`/cards/${cardId}`, cardData).then(
    response => {
      console.log(response);
      const updatedCards = [
        ...getState().cards.cardsList.map(el => {
          if (el.id === response.data.id) {
            return { ...el, ...response.data };
          }
          return el;
        }),
      ];
      const currentCursor = getState().cards.cardsListNextCursor;
      dispatch(cardsList(updatedCards, currentCursor));
      dispatch(setCardSending(false));
      const currentUser = getState().profile.id;
      dispatch(push(`/profile/${currentUser}/projects`));
    },
    error => {
      console.log(error);
      dispatch(setCardSending(false));
    },
  );
};

export const addSingleCard = cardId => (dispatch, getState) => {
  API.get(`/cards/${cardId}`).then(
    response => {
      console.log(response);
      const updatedCards = [{ ...response.data }, ...getState().cards.cardsList];
      const currentCursor = getState().cards.cardsListNextCursor;
      dispatch(cardsList(updatedCards, currentCursor));
    },
    error => {
      console.log(error);
    },
  );
};

export const updateSingleCard = cardId => (dispatch, getState) => {
  API.get(`/cards/${cardId}`).then(
    response => {
      console.log(response);
      const updatedCards = [
        ...getState().cards.cardsList.map(el => {
          if (el.id === response.data.id) {
            return { ...el, ...response.data };
          }
          return el;
        }),
      ];
      const currentCursor = getState().cards.cardsListNextCursor;
      dispatch(cardsList(updatedCards, currentCursor));
    },
    error => {
      console.log(error);
    },
  );
};

export const deleteCard = id => (dispatch, getState) => {
  return API.delete(`/cards/${id}`).then(
    response => {
      Logger.info(response.data);
      dispatch(getCardsList(1));
      dispatch(toggleDeleteDialog({ isShow: false, isDeleteUserId: null }));
    },
    error => {
      Logger.info(error);
    },
  );
};

export const createShipmentData = (cardId, userId, data) => (dispatch, getState) => {
  dispatch(setCardSending(true));
  API.post("/cards/shipment_data", data).then(
    response => {
      console.log(response);
      dispatch(updateSingleCard(cardId));
      dispatch(setCardSending(false));
      dispatch(push(`/profile/${userId}/projects`));
      dispatch(setCardAdd(true));
    },
    error => {
      console.log(error);
      dispatch(setCardSending(false));
    },
  );
};

export const updateShipmentData = (cardId, userId, orderId, data) => (dispatch, getState) => {
  dispatch(setCardSending(true));
  API.patch(`/cards/shipment_data/${orderId}`, data).then(
    response => {
      console.log(response);
      dispatch(updateSingleCard(cardId));
      dispatch(setCardSending(false));
      dispatch(push(`/profile/${userId}/projects`));
      dispatch(setCardAdd(true));
    },
    error => {
      console.log(error);
      dispatch(setCardSending(false));
    },
  );
};

export const createPlanningResults = (cardId, userId, data) => (dispatch, getState) => {
  dispatch(setCardSending(true));
  API.post("/cards/planning_results", data).then(
    response => {
      console.log(response);
      dispatch(updateSingleCard(cardId));
      dispatch(setCardSending(false));
      dispatch(push(`/profile/${userId}/projects`));
      dispatch(setCardAdd(true));
    },
    error => {
      console.log(error);
      dispatch(setCardSending(false));
    },
  );
};

export const updatePlanningResults = (cardId, userId, orderId, data) => (dispatch, getState) => {
  dispatch(setCardSending(true));
  API.patch(`/cards/planning_results/${orderId}`, data).then(
    response => {
      console.log(response);
      dispatch(updateSingleCard(cardId));
      dispatch(setCardSending(false));
      dispatch(push(`/profile/${userId}/projects`));
      dispatch(setCardAdd(true));
    },
    error => {
      console.log(error);
      dispatch(setCardSending(false));
    },
  );
};

