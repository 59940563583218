import Logger from "../modules/Logger";
import { API } from "../modules/Api";
// import SocketService from "../modules/Socket";
import { markasreadSidebarContacts } from "../actions";

export const TOGGLE_CHATBOX = "TOGGLE_CHATBOX";
export const SET_CHATBOX_USER = "SET_CHATBOX_USER";
export const START_GET_CHATBOX_MESSAGES = "START_GET_CHATBOX_MESSAGES";
export const OBTAIN_CHATBOX_MESSAGES_DATA = "OBTAIN_CHATBOX_MESSAGES_DATA";
export const OBTAIN_CHATBOX_MESSAGES_ERROR = "OBTAIN_CHATBOX_MESSAGES_ERROR";

export function toggleChatBox(isOpen) {
  return {
    type: TOGGLE_CHATBOX,
    payload: isOpen,
  };
}

function setChatBoxUser(userId) {
  return {
    type: SET_CHATBOX_USER,
    payload: userId,
  };
}

export const changeChatBoxUser = userId => (dispatch, getState) => {
  dispatch(setChatBoxUser(userId));
  dispatch(getChatBoxMessages(1));
};

function startGetChatBoxMessages() {
  return {
    type: START_GET_CHATBOX_MESSAGES,
  };
}

function obtainChatBoxMessagesData(data, nextCursor) {
  return {
    type: OBTAIN_CHATBOX_MESSAGES_DATA,
    payload: { data, nextCursor },
  };
}

function obtainChatBoxMessagesError(error) {
  return {
    type: OBTAIN_CHATBOX_MESSAGES_ERROR,
    payload: error,
  };
}

export const getChatBoxMessages = page => (dispatch, getState) => {
  dispatch(startGetChatBoxMessages(true));
  if (page === 1) {
    dispatch(obtainChatBoxMessagesData([], null));
  }
  const userId = getState().chatBox.currentUser;
  const nextCursor = getState().chatBox.nextCursor;

  return API.get("user/" + userId + "/message", { params: { cursor: nextCursor } }).then(
    response => {
      let currentMessageList = [...getState().chatBox.messageList];
      for (let i = 0; i < response.data.results.length; i++) {
        currentMessageList.unshift(response.data.results[i]);
      }

      const newNextCursor = response.data.next;

      dispatch(obtainChatBoxMessagesData(currentMessageList, newNextCursor));
    },
    error => {
      Logger.info(error);
      dispatch(obtainChatBoxMessagesError(error));
    },
  );
};

export const getSingleChatBoxMessage = messageId => (dispatch, getState) => {
  const userId = getState().chatBox.currentUser;
  return API.get(`/user/${userId}/message/${messageId}`).then(
    response => {
      Logger.info("chatbox single messages", response.data);

      let currentMessageList = [...getState().chatBox.messageList];
      currentMessageList.push(response.data);
      const newNextCursor = response.data.next;

      dispatch(obtainChatBoxMessagesData(currentMessageList, newNextCursor));
    },
    error => {
      Logger.info(error);
      // dispatch(obtainChatBoxMessagesError(error));
    },
  );
};

export const sendChatBoxMessage = message => (dispatch, getState) => {
  const userId = getState().chatBox.currentUser;
  return API.post("user/" + userId + "/message", { text: message }).then(
    response => {
      Logger.info(response.data);
      const currentMessageList = getState().chatBox.messageList;
      const nextCursor = getState().chatBox.nextCursor;
      const messageList = currentMessageList.concat(response.data);
      dispatch(obtainChatBoxMessagesData(messageList, nextCursor));
    },
    error => {
      Logger.info(error);
      // dispatch(obtainChatBoxMessageError(error));
    },
  );
};

export const markasreadChatBoxMessage = userId => (dispatch, getState) => {
  return API.post(`/user/${userId}/message/markasread`).then(
    response => {
      Logger.info(response.data);
      const currentMessageList = [...getState().chatBox.messageList.map(el => ({ ...el, is_read: true }))];
      const nextCursor = getState().chatBox.nextCursor;
      dispatch(obtainChatBoxMessagesData(currentMessageList, nextCursor));
      dispatch(markasreadSidebarContacts(userId));
    },
    error => {
      Logger.info(error);
      // dispatch(obtainChatBoxMessageError(error));
    },
  );
};

// export const unreadMessageOwner = userId => (dispatch, getState) => {
//   return API.post("user/" + userId + "/message/markasread").then(
//     response => {
//       console.log("unreadMessageOwnerResponse", response.data);
//       // const unread_list = [...getState().contact.unread_list];
//       // const unread_listCount = getState().contact.unread_listCount;
//       // const unread_listNextOffset = getState().contact.unread_listNextOffset;
//       // console.log("UNDER UNDER UNDER PM", unread_list);
//       // const sideUserList = unread_list.map(el => (el.id === userId ? { ...el, unread_pm: 0 } : el));
//       // dispatch(unreadPmContacts(sideUserList, null, unread_listNextOffset));
//       // Logger.info(response.data);
//     },
//     error => {
//       console.log("unreadMessageOwnerError", error);
//       // Logger.info(error);
//     },
//   );
// };

// export const unreadMessage = () => (dispatch, getState) => {
//   return API.post("user/owner/message/markasread").then(
//     response => {
//       Logger.info(response.data);
//     },
//     error => {
//       Logger.info(error);
//     },
//   );
// };
