import { API } from "../modules/Api";
import Logger from "../modules/Logger";
export const SMILE_LIST = "SMILE_LIST";
export const SMILE_NOTIFIC = "SMILE_NOTIFIC";
export const SMILE_LIST_ERROR = "SMILE_LIST_ERROR";

function smileList(data, nextCursor) {
  return {
    type: SMILE_LIST,
    payload: { data, nextCursor },
  };
}

function smileListError(error) {
  return {
    type: SMILE_LIST_ERROR,
    payload: error,
  };
}

export function smileNotific(notificCount) {
  return {
    type: SMILE_NOTIFIC,
    payload: notificCount,
  };
}

export const clearSmileList = () => dispatch => {
  dispatch(smileList([], null));
};

export const getSmileList = page => (dispatch, getState) => {
  if (page === 1) {
    dispatch(clearSmileList());
  }
  const nextCursor = getState().smile.smileListNextCursor;
  return API.get("/smileassessment", {
    params: {
      cursor: nextCursor,
    },
  }).then(
    response => {
      console.log("smileList", response.data);
      const currentsmileList = page !== 1 ? [...getState().smile.smileList] : [];
      const smileListResults = [...currentsmileList, ...response.data.results];
      dispatch(smileList(smileListResults, response.data.next));
      dispatch(smileNotific(response.data.unread));
      // dispatch(obtainTeamDataLoading(false));
      // dispatch(unreadPmContacts(response.data));
    },
    error => {
      Logger.info(error);
      dispatch(smileListError(error));
    },
  );
};

export const smileUnread = () => (dispatch, getState) => {
  return API.post("/smileassessment/markasreadall").then(
    response => {
      Logger.info("unread success", response);
      dispatch(smileNotific(0));
    },
    error => {
      Logger.info(error);
    },
  );
};
