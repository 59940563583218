import { API } from "../modules/Api";
import Logger from "../modules/Logger";
import { CancelToken } from "axios";

export const OBTAINT_NOTIFICATIONS_DATA = "OBTAINT_NOTIFICATIONS_DATA";
export const OBTAINT_NOTIFICATIONS_OBJECT_DATA = "OBTAINT_NOTIFICATIONS_OBJECT_DATA";
export const OBTAINT_NOTIFICATIONS_UNREAD_COUNT_DATA = "OBTAINT_NOTIFICATIONS_UNREAD_COUNT_DATA";
export const OBTAINT_NOTIFICATIONS_ERROR = "OBTAINT_NOTIFICATIONS_ERROR";
export const OBTAINT_NOTIFICATIONS_DATA_LOADING = "OBTAINT_NOTIFICATIONS_DATA_LOADING";
export const OBTAINT_NOTIFICATIONS_CANCEL_TOKEN = "OBTAINT_NOTIFICATIONS_CANCEL_TOKEN";
export const OBTAINT_NOTIFICATIONS_OBJECT_CANCEL_TOKEN = "OBTAINT_NOTIFICATIONS_OBJECT_CANCEL_TOKEN";
export const SET_USER_UNREAD_NOTIFICATIONS = "SET_USER_UNREAD_NOTIFICATIONS";

function obtainNotificationsData(data, nextCursor, count) {
  return {
    type: OBTAINT_NOTIFICATIONS_DATA,
    payload: { data, nextCursor, count },
  };
}

function obtainNotificationsUnreadCountData(countUnread) {
  return {
    type: OBTAINT_NOTIFICATIONS_UNREAD_COUNT_DATA,
    payload: { countUnread },
  };
}

function obtainNotificationsObjectData(data) {
  return {
    type: OBTAINT_NOTIFICATIONS_OBJECT_DATA,
    payload: { data },
  };
}

function obtainNotificationsError(error) {
  return {
    type: OBTAINT_NOTIFICATIONS_ERROR,
    payload: error,
  };
}

function obtainNotificationsDataLoading(isLoading) {
  return {
    type: OBTAINT_NOTIFICATIONS_DATA_LOADING,
    payload: isLoading,
  };
}

function obtainNotificationsCancelToken(tokenNow) {
  return {
    type: OBTAINT_NOTIFICATIONS_CANCEL_TOKEN,
    payload: tokenNow,
  };
}

function setUserUnreadNotifications(counter) {
  return {
    type: SET_USER_UNREAD_NOTIFICATIONS,
    payload: counter,
  };
}

export const getUserUnreadNotifications = () => dispatch => {
  API.get("events/journal/user_events", {}).then(
    response => {
      Logger.info(response.data);
      dispatch(setUserUnreadNotifications(response.data.unread_notifications));
    },
    error => {
      Logger.info(error);
      // dispatch(setUserUnreadNotifications(0));
    },
  );
};

export const resetUserUnreadNotifications = () => dispatch => {
  API.post("/events/journal/filter_and_read").then(
    response => {
      Logger.info(response.data);
      dispatch(setUserUnreadNotifications(0));
    },
    error => {
      Logger.info(error);
      // dispatch(setUserUnreadNotifications(0));
    },
  );
};

export const clearNotificationsList = () => dispatch => {
  dispatch(obtainNotificationsData([], null, 0));
};

export const getNotificationsList = page => (dispatch, getState) => {
  const currentCancelToken = getState().notifications.notificationsListCancelToken;
  if (currentCancelToken) {
    currentCancelToken();
  }
  dispatch(obtainNotificationsDataLoading(true));
  if (page === 1) {
    dispatch(clearNotificationsList());
  }
  const nextCursor = getState().notifications.notificationsListCursor;
  API.get("events/journal", {
    cancelToken: new CancelToken(c => {
      dispatch(obtainNotificationsCancelToken(c));
    }),
    params: { cursor: nextCursor },
  }).then(
    response => {
      Logger.info(response);
      const newNextCursor = response.data.next;
      const notificationsList = [
        ...getState().notifications.notificationsList,
        // ...response.data.results.filter(el => el.target !== null),
        ...response.data.results,
      ];
      dispatch(obtainNotificationsData(notificationsList, newNextCursor, response.data.count));
      dispatch(obtainNotificationsDataLoading(false));
    },
    error => {
      Logger.info(error);
      obtainNotificationsError("notifications error");
    },
  );
};

export const readNotification = (readNotificationsList) => (dispatch, getState) => {
  API.post("/events/journal/read", { notifications: readNotificationsList }).then(
    response => {
      Logger.info(response);

      const newNextCursor = response.data.next;
      const currentNotificationsList = [...getState().notifications.notificationsList];
      console.log(currentNotificationsList, readNotificationsList);
      const notificationsList = currentNotificationsList.map(el => (readNotificationsList.includes(el.id) ? {
        ...el,
        unread: false,
      } : el));

      dispatch(obtainNotificationsData(notificationsList, newNextCursor, response.data.count));


    }, err => {
      Logger.info(err);
    });
};

function obtainNotificationsObjectCancelToken(token) {
  return {
    type: OBTAINT_NOTIFICATIONS_OBJECT_CANCEL_TOKEN,
    payload: token,
  };
}

export const clearNotificationsListObject = () => dispatch => {
  dispatch(obtainNotificationsObjectData([], null, 0));
};
export const getNotificationsObject = (page, itemId) => (dispatch, getState) => {
  // Logger.info("Logger.info", data);
  const currentCancelToken = getState().notifications.notificationsListObjectCancelToken;
  if (currentCancelToken) {
    currentCancelToken();
  }
  if (page === 1) {
    dispatch(clearNotificationsListObject());
  }
  API.get("events/journal", {
    cancelToken: new CancelToken(c => {
      dispatch(obtainNotificationsObjectCancelToken(c));
    }),
    params: {
      project: itemId,
      unread: "True",
    },
  }).then(
    response => {
      Logger.info(response);
      const notificationsListObject = [...getState().notifications.notificationsListObject, ...response.data.results];
      const countUnread = response.data.count;
      dispatch(obtainNotificationsObjectData(notificationsListObject));
      dispatch(obtainNotificationsUnreadCountData(countUnread));
      // dispatch(obtainNotificationsDataLoading(false));
    },
    error => {
      Logger.info(error);
      // obtainNotificationsObjectError("notifications object error");
    },
  );
};

export const resetNotifications = (data, projecId) => (dispatch, getState) => {
  API.post("/events/journal/read", { notifications: data }).then(
    response => {
      Logger.info(response);
      const currentNotifications = [
        ...getState().notifications.notificationsList.map(el => (data.includes(el.id) ? { ...el, unread: false } : el)),
      ];
      API.post(`/events/journal/filter_and_read?item=${data}`).then(
        response => {
          console.log("journalRESET", response.data);
          // dispatch(obtainNotificationsObjectData(1));
          // this.props.notificationsList.map(el => (this.props.item.id ? { ...el, unread: false } : el));
          // this.props.getNotificationsObject(1);
        },
        error => {
        },
      );
      API.get("events/journal", {
        params: { unread: "True" },
      }).then(
        response => {
          Logger.info(response);
          const countUnread = response.data.count;
          dispatch(obtainNotificationsUnreadCountData(countUnread));
        },
        error => {
          Logger.info(error);
          obtainNotificationsError("notifications error");
        },
      );
      const currentNotificationsCursor = getState().notifications.notificationsListCursor;
      dispatch(obtainNotificationsData(currentNotifications, currentNotificationsCursor));
    },
    error => {
      Logger.info(error);
      obtainNotificationsError("notifications error");
    },
  );
};

// export const OBTAINT_APPOINTMENTS_NOTIFICATIONS_DATA = "OBTAINT_NOTIFICATIONS_DATA";
// export const OBTAINT_APPOINTMENTS_NOTIFICATIONS_ERROR = "OBTAINT_NOTIFICATIONS_ERROR";

// function obtainAppointmentsNotificationsData(data, cursor) {
//   return {
//     type: OBTAINT_APPOINTMENTS_NOTIFICATIONS_DATA,
//     payload: { data, cursor },
//   };
// }

// function obtainAppointmentsNotificationsError(error) {
//   return {
//     type: OBTAINT_APPOINTMENTS_NOTIFICATIONS_ERROR,
//     payload: error,
//   };
// }

// export const clearAppointmentsNotificationsList = () => dispatch => {
//   dispatch(obtainAppointmentsNotificationsData([], null));
// };

// export const getAppointmentsNotifications = page => (dispatch, getState) => {
//   if (page === 1) {
//     dispatch(clearNotificationsList());
//   }
//   API.get("events/journal", { params: { cursor: getState().notifications.notificationsListCursor } }).then(
//     response => {
//       Logger.info(response);
//       const notificationsList = [...getState().notifications.notificationsList, ...response.data.results];

//       dispatch(obtainAppointmentsNotificationsData(notificationsList, response.data.next));
//     },
//     error => {
//       Logger.info(error);
//       obtainAppointmentsNotificationsError("notifications error");
//     },
//   );
// };

// export const resetAppointmentsNotifications = data => (dispatch, getState) => {
//   API.post("/events/journal/read", { notifications: data }).then(
//     response => {
//       Logger.info(response);
//       const currentNotifications = [
//         ...getState().notifications.notificationsList.map(el => (data.includes(el.id) ? { ...el, unread: false } : el)),
//       ];
//       const currentNotificationsCursor = getState().notifications.notificationsListCursor;
//       dispatch(obtainAppointmentsNotificationsData(currentNotifications, currentNotificationsCursor));
//     },
//     error => {
//       Logger.info(error);
//       obtainAppointmentsNotificationsError("notifications error");
//     },
//   );
// };
